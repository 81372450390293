import Vue from 'vue/dist/vue.js';
// import { createApp } from "vue/dist/vue.esm-bundler";
import CustomMouse from './components/customMouse/customMouse.vue';
// import CustomSlider from './components/customSlider.vue';
// import CustomMap from './components/customMap/customMap.vue';

Vue.component('CustomMouse', CustomMouse);
// Vue.component('CustomSlider', CustomSlider);
// Vue.component('CustomMap', CustomMap);

let allVueInstances = document.getElementsByClassName('js--vue');
for (let el of allVueInstances) {
    new Vue({
        el: el
    })
};


// const app = createApp({})
// app.component('CustomMouse', CustomMouse)

// app.mount('#js--vue')
