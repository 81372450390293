//IMPORT
import { gsap, ScrollTrigger } from 'gsap/all';
import Lottie from 'lottie-web';
gsap.registerPlugin(ScrollTrigger);


// //HEADER DESKTOP + HEADER MOBILE + SCROLLTRIGGER STICKY
let header = document.querySelector('#js--header');
let headerMobile = document.querySelector('#js--header-mobile');

let menuMobile = document.querySelector('#js--menu-mobile');
let hideable_links = header.querySelectorAll('.hideable-links');
let langlinks = header.querySelector('.langlinks');
let menuMobileContent = menuMobile.querySelector('.menu-mobile--content');
let menuMobileLinks = menuMobile.querySelectorAll('.link');

let hamburger_mobile = document.querySelector('#js--hamburger-mobile');

let mobileMenuTl = gsap.timeline({
    paused: true,
    reversed: true
})
.to('html', {overflow:'hidden',duration: .001})
.to(menuMobile, {display:'block',duration: .001})
.to(header, {backgroundColor:'#2D2F31',duration: .001})
.to(headerMobile, {backgroundColor:'#2D2F31',duration: .001})
.to(hideable_links, {autoAlpha:0, duration: .001,display:"none"})
.to(langlinks, {autoAlpha:1, duration: .001,display:"flex"})
// .fromTo(logoMobile, .035, {autoAlpha:1}, {autoAlpha:0})
.addLabel('start')
.fromTo(menuMobileContent, .5, {autoAlpha:0}, {autoAlpha:1},'start')
// .fromTo(headerMobile, .5, {backgroundColor:'#181818'},{backgroundColor:'#fb1e3f'},'start')
.fromTo(menuMobileLinks, .75, {autoAlpha:0}, {autoAlpha:1, stagger: 0.15},'-=.5s');



    const JSON_hambuger_animation_mobile = Lottie.loadAnimation({
        container: hamburger_mobile, // Required
        path: hamburger_mobile.dataset.hamburger, // Required
        renderer: 'svg', // Required
        loop: false, // Optional
        autoplay: false, // Optional
        filterSize: {
            width: '100%',
            height: '100%'
        }
    });
    JSON_hambuger_animation_mobile.setSpeed(2);
    console.log(hamburger_mobile)

    hamburger_mobile.addEventListener('click', function () {
        if (mobileMenuTl.reversed()) {
            JSON_hambuger_animation_mobile.setDirection(1);
            JSON_hambuger_animation_mobile.play();
            setTimeout(() => {
                mobileMenuTl.play();
            }, 500);
        } else {
            mobileMenuTl.reverse().eventCallback('onReverseComplete', function () {
                JSON_hambuger_animation_mobile.setDirection(-1);
                JSON_hambuger_animation_mobile.play();
            });
        };
    })

