import './bootstrap'

import { gsap, ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

import Splide from '@splidejs/splide';

// import { SplideCollectionTransition } from './splide-transition';
import './front-utils'
import './vue-init'

let mode = (window.innerWidth < 993) ? 'mobile' : 'desktop';


const home_slide = document.getElementById('home_slider');
if(home_slide){
    // var index_label =  document.getElementById('collections_index_label');
    var home_splide =
    new Splide( '#home_slider', {
        type: 'fade',
        // focus: 'end',
        pagination: true,
        pauseOnFocus: false,
        pauseOnHover: false,
        arrows: false,
        drag: false,
        speed: "800",
        flickPower: 900,
        autoplay: true,
        interval: 2300,
        rewind: true,
    });
    home_splide.on( 'pagination:mounted', function ( data ) {
        data.list.classList.add( 'splide__pagination--custom' );
        data.items.forEach( function ( item ) {
            item.button.textContent = names.at(item.page).name;
        });
    });
    home_splide.mount();

    home_splide.on( 'active', function(e) {
        const element = document.querySelectorAll('.title');
        element[e.index].classList.add('animate__fadeInUp');
    });

    home_splide.on( 'inactive', function(e) {
        const element = document.querySelectorAll('.title');
        element[e.index].classList.remove('animate__fadeInUp');
    });
}

// const family_slide = document.getElementById('family');
// if(family_slide){
//     var family_splide = new Splide( '#family', {
//         type   : 'loop',
//         padding: { left: 0, right: '10%' },
//         start:0,
//         perPage: 2,
//         perMove: 1,
//         focus:0,
//         trimSpace: false,
//         gap: '2em',
//         pagination: false,
//         updateOnMove: true,
//         autoplay: 'play',
//         breakpoints: {
//             640: {
//                 perPage: 1,
//                 padding: '0',
//             },
//         }
//     })

//     family_splide.mount({},SplideCollectionTransition);
// }


const carousel_slide = document.getElementById("carousel_slide");
if(carousel_slide){

    document.addEventListener( 'DOMContentLoaded', function () {
        new Splide('#carousel_slide', {
            type: 'loop',
            perPage: 4,
            focus: 'center',
            pagination: false,
            autoplay: 'true',
            throttle: 300,
            updateOnMove: true,
            // getPadding: right,
            padding: {
                left: '2rem',
                right: '2rem'
            },
            gap: '2.5rem',
            breakpoints: {
                768: {
                    perPage: 1.5,
                    focus: 'center',
                    pagination: false,
                    autoplay: 'false',
                    gap: '1.5rem',
                },
            }
        } ).mount();
    });
}


// Slider creations

const creations_slide = document.getElementById("creations_slide");
if(creations_slide){

    document.addEventListener( 'DOMContentLoaded', function () {
        new Splide('#splide', {
            type: 'loop',
            perPage: 2,
            focus: 'right',
            pagination: false,
            autoplay: 'true',
            gap: '1.5rem',
            // throttle: 300,
            padding: {
                left: '10rem',
                right: '10rem'
            },
            updateOnMove: true,
            breakpoints: {
                768: {
                    perPage: 1,
                    padding: {
                        left:0,
                    },
                    gap:'1rem'
                },
            },
        } ).mount();
    });
}

// Slider about

const about_slide = document.getElementById("about_slide");
if(about_slide){

    document.addEventListener( 'DOMContentLoaded', function () {
        new Splide('#splide', {
            type: 'loop',
            perPage: 1.5,
            focus: 'center',
            pagination: false,
            autoplay: 'true',
            gap: '1.5rem',
            arrows: false,
            updateOnMove: true,
        } ).mount();
    });
}

// Slider quarries hp

const quarries_slide = document.getElementById("quarries_slide");
if(quarries_slide){

    document.addEventListener( 'DOMContentLoaded', function () {
        new Splide('#splide', {
            type: 'loop',
            perPage: 1.5,
            focus: 'center',
            pagination: false,
            autoplay: true,
            gap: '1.5rem',
            updateOnMove: true,
            arrows: false,
        } ).mount();
    });
}

// Slider family

const family_mob_slide = document.getElementById("family_mob_slide");
if(family_mob_slide){

    document.addEventListener( 'DOMContentLoaded', function () {
        new Splide('#splide', {
            type: 'loop',
            perPage: 1,
            focus: 'center',
            pagination: false,
            autoplay: 'true',
            gap: '1.5rem',
            updateOnMove: true,
        } ).mount();
    });
}


// Section Creations

const introSection = document.getElementById("intro--section");
if(introSection){

    gsap.to(".text-section", {
        xPercent: -50,
        ease: "none",
        scrollTrigger: {
            trigger:introSection,
            markers: false,
            start: "top",
            end: "bottom",
            pinSpacing: false,
            pin: false,
            scrub: 1
        },
    });

    let imgParallax = document.querySelectorAll(".img-parallax");

    gsap.set(".intro__img--1", { yPercent: -5});
    gsap.set(".intro__img--2", { yPercent: -10});
    gsap.set(".intro__img--3", { yPercent: -5});
    gsap.set(imgParallax, { yPercent: 20});


    gsap.to(".intro__img--1", {
        yPercent: '5',
        ease: "none",
        scrollTrigger: {
            trigger: ".intro__img--1",
            scrub: 1
        },
    });

    gsap.to(".intro__img--2", {
        yPercent: '10',
        ease: "none",
        scrollTrigger: {
            trigger: ".intro__img--2",
            scrub: 1
        },
    });

    gsap.to(".intro__img--3", {
        yPercent: '5',
        ease: "none",
        scrollTrigger: {
            trigger: ".intro__img--3",
            scrub: 1
        },
    });

    gsap.to(imgParallax, {
        yPercent: '-20',
        ease: "none",
        scrollTrigger: {
            trigger: "#intro--section",
            scrub: 1
        },
    });
}


// Section Three Images

const imagesSection = document.getElementById("images--section");
if(imagesSection){

    // gsap.set(".image-right", { yPercent: 15});
    // gsap.set(".image-left", { yPercent: 15});
    // gsap.set(".icon-right", { yPercent: 10});
    gsap.set(".zoom-img", { yPercent: -3});

    gsap.to(".image-right", {
        yPercent: '-15',
        ease: "none",
        scrollTrigger: {
            trigger: imagesSection,
            scrub: 2
        },
    });

    gsap.to(".image-left", {
        yPercent: '-10',
        ease: "none",
        scrollTrigger: {
            trigger: imagesSection,
            scrub: 2
        },
    });

    gsap.to(".icon-right", {
        yPercent: '-10',
        ease: "none",
        scrollTrigger: {
            trigger: imagesSection,
            scrub: 4
        },
    });

    gsap.to(".zoom-img", {
        yPercent: '3',
        ease: "none",
        scrollTrigger: {
            trigger: imagesSection,
            scrub: 2
        },
    });
}

// Horizontal Section
const horizontalSection = document.getElementById("horizontal--section");
if(horizontalSection){

    if (mode == 'desktop') {

        let sections = gsap.utils.toArray(".panel");

        let horizontalScroll = gsap.to(sections, {
            xPercent: -50 * (sections.length),
            ease: "none", // <-- IMPORTANT!
            scrollTrigger: {
                trigger: ".panel--container",
                pin: ".panel--container",
                scrub: 0,
                // markers: true,
                start: "top",
                end: "+=2000",
                ease: "SlowMo.easeOut",
                scrub: 1
            }
        });

        gsap.set(".container--text", { yPercent: -15});
        let cardItem = document.querySelectorAll(".item");
        gsap.set(cardItem, { xPercent: 30});


        gsap.to(".container--text", {
            yPercent: '-10',
            ease: "none",
            scrollTrigger: {
                trigger: ".panel--container",
                scrub: 3
            },
        });
        gsap.to(cardItem, {
            xPercent: '-45',
            ease: "none",
            scrollTrigger: {
                trigger: ".panel--container",
                scrub: 4,
                duration: 7,
                // markers: true,
                start: "top +=200",
                end: "top +=600",
                ease: "Power3.easeOut",
            },
        });

        gsap.set(".img--zoom", {scale: 1.2});
        gsap.to(".img--zoom", {scale: 1, scrollTrigger: {
            trigger: ".panel--container",
            duration: 7,
            scrub: 3,
        }});
    }
}

// Section Creations

const introDesign = document.getElementById("intro--design");
if(introDesign){

    let imgParallax = document.querySelectorAll(".img-parallax");

    gsap.set(".vertical--img", { yPercent: -5});
    gsap.set(".horizontal--img", { yPercent: 5});


    gsap.to(".vertical--img", {
        yPercent: '5',
        ease: "none",
        scrollTrigger: {
            trigger: ".horizontal--img",
            scrub: 1
        },
    });

    gsap.to(".horizontal--img", {
        yPercent: '10',
        ease: "none",
        scrollTrigger: {
            trigger: ".vertical--img",
            scrub: 1
        },
    });
}

// Icons Header

document.querySelectorAll('.icon-header').forEach((header_icon,index)=>{
    header_icon.addEventListener('mouseover', function () {
        let name = header_icon.dataset.id;
        document.getElementById(name).style.fill = '#D52E34';
        console.log(document.getElementById(name).style.fill)
    })
    header_icon.addEventListener('mouseleave', function () {
        let name = header_icon.dataset.id;
        document.getElementById(name).style.fill = '#f5f5f5';
    })
});
