//IMPORT
import { gsap, ScrollTrigger, Expo } from 'gsap/all';
gsap.registerPlugin(ScrollTrigger);
// import SplitText from './SplitText';
import Lottie from 'lottie-web';

//ULTIMA REVISIONE 04/10/21
// TODO: dinamicizzare triggerHook(entrambi?), default duration & deelay, breakpoints

//EXAMPLE
// data-anim-trigger='["NOME_ANIMAZIONE", DURATION, DEELAY]'
// data-anim-trigger='["NOME_ANIMAZIONE"]'
// data-anim-scroll='["NOME_ANIMAZIONE", DURATION, DEELAY]'
// data-anim-scroll='["NOME_ANIMAZIONE"]'

// <div data-anim-trigger='["moveUp",0.75,0.2]' class="">
//     <div class="un-solo-contenitore-figlio-obbligatorio">
//
//     </div>
// </div>

// let controller = new ScrollMagic.Controller();
let dataAnimationsTrigger = document.querySelectorAll('*[data-anim-trigger]');
let dataAnimationsScroll = document.querySelectorAll('*[data-anim-scroll]');
let dataAnimations = document.querySelectorAll('*[data-anim-scroll],*[data-anim-trigger]');
let mode = (window.innerWidth < 993) ? 'mobile' : 'desktop';

//GESTIONE DESKTOP/MOBILE
let mediaQuery = window.matchMedia('(max-width: 992px)');
mediaQuery.addListener(
    function(e){
        if(e.matches){
            mode = 'mobile';
        } else {
            mode = 'desktop';
        }
        generateAnimations(mode);
    }
    );

    // setTimeout(function () {
    //     generateAnimations(mode);
    // }, 100);
    window.addEventListener('DOMContentLoaded', (event) => {
        generateAnimations(mode);
    });

    function generateAnimations(mode){
        dataAnimations.forEach((el, i) => {
            let dataType = el.dataset[Object.keys(el.dataset)[0]];
            if (el.childElementCount == 1) {
                let element = el.firstElementChild;
                var delay = 0.35;
                var duration = .5;
                let animationType;
                let animationArray;
                let scene;
                try {
                    animationArray = JSON.parse(dataType);
                    animationType = animationArray[0];

                    //duration
                    if (animationArray[1]) {
                        duration = animationArray[1];
                    }
                    //deelay
                    if (animationArray[2]) {
                        delay = animationArray[2];
                    }
                } catch (e) {
                    console.log(e)
                }

                let tl = gsap.timeline({
                    paused: Object.keys(el.dataset)[0] == 'animTrigger' ? true : false,
                });
                tl.defaultEase = Expo.easeInOut;


                // TODO: test x rigenerazione animazioni, sennò provare sul listener del matchMedia
                // https://greensock.com/forums/topic/13642-kill-timeline-doesnt-do-anything/
                // TODO: testare anche il killAll();
                // TweenMax.killTweensOf(myObject);
                // TweenMax.killAll();
                if (el.timeline) {
                    el.timeline.kill();
                }
                switch (animationType) {
                    // case 'fadeInCta':
                    // tl.fromTo(element, {
                    //     autoAlpha: 0,
                    //     yPercent: 10
                    // }, {
                    //     duration: duration,
                    //     delay: delay,
                    //     autoAlpha: 1,
                    //     yPercent: 0,
                    //     stagger: 0.5,
                    //     ease: "Power3.easeOut",
                    //     y: -100
                    // });
                    // break;

                    case 'animTitle':
                    let redLine = document.querySelectorAll(".js--red-line");
                    tl.set(redLine, {visibility:"hidden"});

                    if (mode == 'desktop') {
                        tl.fromTo(element, {
                            autoAlpha: 0,
                            yPercent: 100
                        }, {
                            autoAlpha: 1,
                            duration: 1,
                            delay: 0.3,
                            yPercent: 5,
                        },'start')
                        .fromTo(redLine,
                            {
                            visibility: "visible",
                            width: 0,
                            background: "#d52e348b",
                            alpha: 0
                        }, {
                            height: 1.4,
                            width: "48%",
                            background: "#d52e348b",
                            opacity: "1",
                            ease: "power4.out",
                            delay: 2.3,
                            duration: 1.5,
                            alpha: 1,
                        },'-=3');
                    }
                    if (mode == 'mobile') {
                        tl.fromTo(element, {
                            autoAlpha: 0,
                            yPercent: 100
                        }, {
                            autoAlpha: 1,
                            duration: 1,
                            delay: 0.3,
                            yPercent: 5,
                        },'start')
                        .fromTo(redLine,
                            {
                            visibility: "visible",
                            width: 0,
                            background: "#d52e348b",
                            alpha: 0
                        }, {
                            height: 1.4,
                            width: "85%",
                            background: "#d52e348b",
                            opacity: "1",
                            ease: "power4.out",
                            delay: 2.3,
                            duration: 1.5,
                            alpha: 1,
                        },'-=3');
                    }
                    break;

                    case 'fadeInUpAbout':
                        tl.fromTo(element, {
                            autoAlpha: 0,
                            yPercent: 100
                        }, {
                            autoAlpha: 1,
                            duration: 1,
                            delay: .1,
                            yPercent: 0,
                        })
                        break;

                    case 'fadeInUp':
                    tl.fromTo(element, {
                        autoAlpha: 0,
                        yPercent: 20
                    }, {
                        duration: duration,
                        delay: delay,
                        autoAlpha: 1,
                        yPercent: 0,
                        stagger: 0.5,
                        ease: "power3.out"
                    });
                    break;

                    case 'zoomIn':
                    tl.fromTo(element, {
                        scale: 1
                    }, {
                        duration: duration,
                        delay: delay,
                        stagger: 0.5,
                        scale: 1.1,
                        ease: "power4.out"
                    });
                    break;

                    case 'zoomInBg':
                    if (mode == 'desktop') {
                    tl.fromTo(el, {
                        backgroundSize:"100% 100%",
                    }, {
                        duration: 4,
                        delay: 0.2,
                        backgroundSize: "+=10% +=10%",
                        autoRound:false,
                        ease: "expo.easeOut"
                    });
                    }
                    break;

                    //CUSTOM ANIMATION
                    // case 'splittedWords':
                    //     let split = new SplitText(element, { type: 'words, chars' });
                    //     tl.fromTo(split.chars, {
                    //         y: 10,
                    //         autoAlpha:0
                    //     }, {
                    //         y: 0,
                    //         autoAlpha:1,
                    //         stagger: 0.02,
                    //         duration: duration,
                    //         delay: delay
                    //     });
                    //     break;

                    // case 'hp-parallax':
                    // let img1 = el.querySelector('.js--img-1');
                    // let img2 = el.querySelector('.js--img-2');

                    // tl.addLabel('start')
                    // .fromTo(img1, {
                    //     y: 300
                    // }, {
                    //     duration: duration,
                    //     delay: delay,
                    //     y: 0,
                    //     ease:"none"
                    // },'start')
                    // .fromTo(img2, {
                    //     y: 200
                    // }, {
                    //     duration: duration,
                    //     delay: delay,
                    //     y: 0,
                    //     ease:"none"
                    // },'start');
                    // break;

                    // case 'valueBox':
                    // let lottieAnim = element.querySelector('*[data-json]');
                    // let content = element.querySelector('.js--content');
                    // if(lottieAnim){
                    //     let anim = Lottie.loadAnimation({
                    //         container: lottieAnim, // Required
                    //         path: lottieAnim.dataset.json, // Required
                    //         renderer: 'svg', // Required
                    //         loop: false, // Optional
                    //         autoplay: false, // Optional
                    //         filterSize: {
                    //             width: '100%',
                    //             height: '100%'
                    //         }
                    //     });
                    //     anim.setSpeed(1);
                    //     tl.fromTo(content, {
                    //         autoAlpha: 0,
                    //         yPercent: 100
                    //     }, {
                    //         duration: 1,
                    //         delay: delay,
                    //         autoAlpha: 1,
                    //         yPercent: 0
                    //     })
                    //     .add(function(){
                    //         anim.play();
                    //     },'-=.25s');
                    // }
                    // break;

                    // case 'catalogueShow':
                    // let imgCs  = element.querySelector('.js--image');
                    // let titleCs  = element.querySelector('.js--title');
                    // let contentCs  = element.querySelector('.js--content');
                    // let watermarkCs  = element.querySelector('.js--watermark');
                    // let redLineCs = element.querySelector('.js--ribbon');
                    // tl.addLabel('start');
                    // tl.fromTo(titleCs, {
                    //     yPercent: 100
                    // }, {
                    //     duration:1,
                    //     delay: .5,
                    //     yPercent: 0
                    // },'start')
                    // .fromTo(redLineCs, {
                    //     width: '0%'
                    // }, {
                    //     duration: 1,
                    //     delay: .5,
                    //     width: '100%',
                    //     ease: "power4.in"
                    // },'start')
                    // .addLabel('middle')
                    // .fromTo(contentCs, {
                    //     opacity: 0
                    // }, {
                    //     duration:1,
                    //     opacity: 1
                    // },'middle')
                    // .fromTo(imgCs, {
                    //     autoAlpha: 0
                    // }, {
                    //     duration:1,
                    //     autoAlpha: 1
                    // },'middle')
                    // .fromTo(watermarkCs, {
                    //     autoAlpha: 0
                    // }, {
                    //     duration:1,
                    //     autoAlpha: .5
                    // },'middle')

                    // break;


                    case 'hpIntro':
                    // let img  = element.querySelector('.js--img');
                    let hpTitle  = element.querySelector('.js--title');
                    let hpSubtitle  = element.querySelector('.js--subtitle');
                    let titleSpans = hpTitle.querySelectorAll('span');
                    let buttonHp = element.querySelector('.js--button-hp');
                    let buttonWidth = element.querySelector('.width--button-hp');
                    let buttonMobile = element.querySelector('.js--button-mobile');

                    tl.set(buttonHp, {display:"none"});
                    tl.set(buttonWidth, {display:"none"});

                    tl.addLabel('start')
                    // tl.fromTo(img, {
                    //     scale: 1
                    // }, {
                    //     duration: .4,
                    //     delay: .1,
                    //     stagger: 0.5,
                    //     scale: 1.5,
                    //     ease: "power4.out"
                    // })
                    .fromTo(titleSpans, {
                        autoAlpha: 0,
                        yPercent: 100
                    }, {
                        autoAlpha: 1,
                        duration: 1,
                        delay: .1,
                        yPercent: 0,
                        stagger: { // wrap advanced options in an object
                            each: .55,
                            ease: "none"
                        }
                    })

                    tl.fromTo(hpSubtitle, {
                        autoAlpha: 0,
                        yPercent: 100
                    }, {
                        duration: .9,
                        autoAlpha: 1,
                        delay: 0.1,
                        yPercent: 0,
                    });


                    if (mode == 'desktop') {
                        tl.addLabel('middle')
                        .fromTo(buttonWidth, {
                            autoAlpha: 0,
                            display:"block",
                            width: '0%'
                        }, {
                            duration: .8,
                            delay: .8,
                            autoAlpha: 1,
                            width: '40%',
                            ease: "Power2.easeOut"
                        },'-=2')
                        .fromTo(buttonHp, {
                            autoAlpha: 0,
                            display:"block",
                            // yPercent: 100
                        }, {
                            duration: 1.6,
                            delay: 2.5,
                            autoAlpha: 1,
                            // yPercent: 0,
                            ease: "Power4.easeOut"
                        },'-=3');
                    }
                    if (mode == 'mobile') {
                        tl.fromTo(buttonMobile, {
                            autoAlpha: 0,
                            yPercent: 30
                        }, {
                            autoAlpha: 1,
                            duration: 0.5,
                            delay: 1.5,
                            yPercent: 0,
                        },'-=3')
                    }
                    break;
                }

                el.timeline = tl;

                setTimeout(function () {
                    if (el.hasAttribute("data-anim-trigger")) {
                        //CREO SCENA
                        el.scrolltrigger = ScrollTrigger.create({
                            //markers: true,
                            trigger: el,
                            endTrigger:"html",
                            start: "top 90%",
                            end: "bottom bottom",
                            scrub: 1,
                            onToggle: (self) => {
                                if (self.isActive) {
                                    el.timeline.play();
                                }
                            }
                        });
                    }
                    else if (el.hasAttribute("data-anim-scroll")) {
                        el.scrolltrigger = ScrollTrigger.create({
                            //markers: true,
                            trigger: el,
                            animation: el.timeline,
                            // pin:true,
                            start: "top 90%",
                            end: "bottom bottom",
                            scrub: 1
                        });
                    }
                }, 10);

            } else {
                console.log('due elementi figli, raggruppali in uno');
            }
        });
    };

