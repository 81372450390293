import { gsap, ScrollTrigger, Expo } from 'gsap/all';
import Lottie from 'lottie-web';
gsap.registerPlugin(ScrollTrigger);


//LOTTIE ANIMATIONS INIT
let lotties = document.querySelectorAll('[data-lottie]');
lotties.forEach((el, i) => {
    el.anim = Lottie.loadAnimation({
        container: el, // Required
        path: el.dataset.lottie, // Required
        renderer: 'svg', // Required
        loop: true, // Optional
        autoplay: true, // Optional
        viewBoxOnly: true,
        viewBoxSize: {
            width: '36px',
            height: '36px'
        },
        // filterSize: {
        //     width: '50px',
        //     height: '50px'
        // }
    });
    // el.anim.setSpeed(0.25);

    // el.scrolltrigger = ScrollTrigger.create({
    //     //markers: true,
    //     trigger: el,
    //     endTrigger:"html",
    //     start: "top 90%",
    //     end: "bottom bottom",
    //     scrub: 1,
    //     onToggle: (self) => {
    //         if (self.isActive) {
    //             el.anim.play();
    //         }
    //     }
    // });

});
