import Iodine from '@caneara/iodine';
import { gsap } from 'gsap/all';
import axios from "axios"

const instance = new Iodine();
let cf = document.querySelector('#js--contact-form')
// let nf = document.querySelector('#js--newsletter-form')
let resp = document.querySelector('#js--contact-form-response');
let respTxt = document.querySelector('#js--contact-form-response-text');

if (cf) {
    const rules = {
        name : ['required', 'string', 'minLength:3', 'maxLength:255'],
        surname : ['required', 'string', 'minLength:3', 'maxLength:255'],
        email: ['required', 'string', 'minLength:6', 'maxLength:150', 'email'],
        message : ['required', 'string', 'minLength:3'],
        privacy: ['required'],
    };
    let valid = false;

    cf.addEventListener('submit', function () {
        event.preventDefault();
        valid = true;

        for (const field in rules) {
            if (field == 'privacy') {
                let input = cf.querySelector('#'+field);
                if (input.checked !== true) {
                    valid = false;
                    input.parentElement.classList.add('error');
                }
            } else {
                let input   = cf.querySelector('#'+field);
                let message = cf.querySelector('#'+field+'+span.message');
                message.classList.add('d-none');
                let result = instance.assert(input.value, rules[field]);
                if (result.valid !== true) {
                    valid = false;
                    message.classList.remove('d-none');
                }
            }

        }
        if (valid) {
            let newsletter = 0;
            if(cf.querySelector('input[name="newsletter"]')){
                newsletter = cf.querySelector('input[name="newsletter"]').checked;
            }
            axios.post(cf.action, {
                name: cf.querySelector('input[name="name"]').value,
                surname: cf.querySelector('input[name="surname"]').value,
                //city: cf.querySelector('input[name="city"]').value,
                //business_name: cf.querySelector('input[name="business_name"]').value,
                phone: cf.querySelector('input[name="phone"]').value,
                email: cf.querySelector('input[name="email"]').value,
                message: cf.querySelector('textarea[name="message"]').value,
                //newsletter: newsletter
            })
            .then((response) => {
                respTxt.innerHTML = response.data.message;
                cf.querySelector('input[name="name"]').value = '';
                cf.querySelector('input[name="surname"]').value = '';
                cf.querySelector('input[name="phone"]').value = '';
                cf.querySelector('input[name="email"]').value = '';
                cf.querySelector('textarea[name="message"]').value = '';
                let tl = gsap.timeline();
                tl.to(resp,.001,{display:'block'})
                .to(resp,.5,{autoAlpha:1})
                .to(resp,.5,{autoAlpha:0},'+=5s')
                .to(resp,.001,{display:'none'});
            })
            .catch((error) => {
                console.log(error);
            });
        }
    });
}else if(resp){
    let tl = gsap.timeline();
    tl.to(resp,.001,{display:'block'})
    .to(resp,.5,{autoAlpha:1})
    .to(resp,.5,{autoAlpha:0},'+=5s')
    .to(resp,.001,{display:'none'});
}

