import { gsap } from 'gsap/all';
import Lottie from 'lottie-web';

window.LottieScrollTrigger = (vars) => {
    let playhead = {
            frame: 0
        }
    let target = gsap.utils.toArray(vars.target)[0];
    let st = {
        trigger: target,
        anticipatePin: 1,
        pin: true,
        start: "top top",
        // end: "+=500",
        scrub: 1,
    };
    let animation = Lottie.loadAnimation({
        container: target,
        renderer: vars.renderer || "svg",
        loop: false,
        autoplay: false,
        path: vars.path
    });
     // override parametri default
    for (let p in vars) {
        st[p] = vars[p];
    }
    animation.addEventListener("DOMLoaded", function() {
        gsap.to(playhead, {
            frame: animation.totalFrames - 1,
            ease: "none",
            onUpdate: () => animation.goToAndStop(playhead.frame, true),
            scrollTrigger: st
        });
    });
}
