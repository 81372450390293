<template>
    <div>
        <!--
        <div ref="cursor" class="cursor">
        	<svg viewBox="0 0 109.31 109.31">
        	    <circle ref="cursorCircleIn" class="circle-in" cx="54.65" cy="54.66" r="5.32" :fill="color"/>
        	</svg>
        </div>
        -->

        <div ref="cursorCircle" class="cursor-circle">
            <span id="js--cursor-text" ref="text" class="cursor-text" style="opacity: 0; visibility: hidden;"><!-- JS --></span>
        	<svg viewBox="0 0 100 100">
                <circle ref="cursorCircleOut" class="circle-out" cx="50" cy="50" r="50" :fill="color" />
                <!-- <rect ref="cursorCircleOut" class="circle-out" width="100" height="100" :fill="color" /> -->
        	</svg>
            <!-- <span id="js--ig-icon" ref="igicon" class="cursor-icon" style="opacity: 0; visibility: hidden;">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.52 31.51"><path d="M15.76,7.68a8.08,8.08,0,1,0,8.08,8.07,8.07,8.07,0,0,0-8.08-8.07Zm0,13.33A5.26,5.26,0,1,1,21,15.75h0A5.26,5.26,0,0,1,15.76,21ZM26.06,7.35a1.89,1.89,0,1,1-1.89-1.89,1.89,1.89,0,0,1,1.89,1.89ZM31.4,9.26a9.29,9.29,0,0,0-2.54-6.6A9.38,9.38,0,0,0,22.26.12c-2.6-.15-10.4-.15-13,0a9.37,9.37,0,0,0-6.6,2.53A9.42,9.42,0,0,0,.11,9.25c-.14,2.6-.14,10.4,0,13a9.34,9.34,0,0,0,2.55,6.6,9.39,9.39,0,0,0,6.6,2.55c2.6.15,10.4.15,13,0a9.3,9.3,0,0,0,6.6-2.55,9.33,9.33,0,0,0,2.54-6.6c.15-2.6.15-10.39,0-13ZM28.05,25a5.3,5.3,0,0,1-3,3c-2.08.83-7,.64-9.29.64s-7.22.18-9.29-.64a5.3,5.3,0,0,1-3-3c-.82-2.07-.63-7-.63-9.29s-.19-7.22.63-9.28a5.3,5.3,0,0,1,3-3c2.07-.83,7-.64,9.29-.64s7.22-.18,9.29.64a5.3,5.3,0,0,1,3,3c.82,2.07.63,7,.63,9.28S28.87,23,28.05,25Z" style="fill:#fcfcfb"/></svg>
            </span>
            <span id="js--fb-icon" ref="fbicon" class="cursor-icon" style="opacity: 0; visibility: hidden;">
                <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.52 31.51"><path d="M23.09,17.72l.88-5.7h-5.47v-3.7c0-1.56,.76-3.08,3.21-3.08h2.49V.38c-1.46-.23-2.94-.36-4.42-.38-4.46,0-7.46,2.73-7.46,7.68v4.32H7.32v5.7h5v13.81h6.17v-13.79h4.6Z" style="fill:#f5f5f5;"/></svg>
            </span>
            <span id="js--in-icon" ref="inicon" class="cursor-icon" style="opacity: 0; visibility: hidden;">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.5 31.5"><path d="M7.05,31.5H.52v-21H7.05ZM3.78,7.6A3.8,3.8,0,1,1,7.56,3.78h0A3.8,3.8,0,0,1,3.78,7.6ZM31.49,31.5H25V21.26c0-2.44-.05-5.57-3.4-5.57s-3.92,2.65-3.92,5.4V31.5H11.14v-21h6.27v2.87h.09a6.87,6.87,0,0,1,6.18-3.4c6.61,0,7.82,4.35,7.82,10V31.5Z" style="fill:#fcfcfb"/></svg>
            </span>
            <span id="js--down-icon" ref="downicon" class="cursor-icon" style="opacity: 0; visibility: hidden;">
                <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.52 31.51"><path id="b" d="M28.73,11.12h-7.41V0H10.2V11.12H2.79l12.97,12.97,12.97-12.97ZM2.79,27.8v3.71H28.73v-3.71H2.79Z" style="fill:#fff;"/></svg>
            </span>
            <span id="js--scroll-icon" ref="scrollicon" class="cursor-icon" style="opacity: 0; visibility: hidden;">
                <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45.59 45.59"><polyline points="44.3 22.79 22.8 44.3 1.29 22.79" style="fill:none; stroke:#fff;"/><line x1="22.8" y1="44.3" x2="22.8" y2="1.29" style="fill:none; stroke:#fff;"/></svg>
            </span> -->
            <span id="js--prev-icon" ref="previcon" class="cursor-icon" style="opacity: 0; visibility: hidden;">
                <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><path id="b" d="M18.07,43.08c-.77,.77-2.01,.77-2.78,0h0L1.39,29.18c-.77-.77-.77-2.01,0-2.78h0L15.29,12.51c.77-.77,2.01-.77,2.78,0s.77,2.01,0,2.78L5.56,27.79l12.51,12.51c.77,.77,.77,2.01,0,2.78h0Z M54.77,27.79c0,1.09-.88,1.97-1.96,1.97,0,0,0,0,0,0H2.78c-1.09,.04-2-.81-2.04-1.9s.81-2,1.9-2.04c.05,0,.09,0,.14,0H52.8c1.09,0,1.97,.87,1.97,1.96,0,0,0,0,0,0Z" style="fill:#f5f5f5;"/></svg>
            </span>
            <span id="js--next-icon" ref="nexticon" class="cursor-icon" style="opacity: 0; visibility: hidden;">
                <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60"><path id="b" d="M37.52,12.51c.77-.77,2.01-.77,2.78,0h0l13.89,13.89c.77,.77,.77,2.01,0,2.78h0l-13.89,13.89c-.77,.77-2.01,.77-2.78,0s-.77-2.01,0-2.78l12.51-12.51-12.51-12.51c-.77-.77-.77-2.01,0-2.78h0Z M.82,27.79c0-1.09,.88-1.97,1.96-1.97,0,0,0,0,0,0H52.8c1.09-.04,2,.81,2.04,1.9s-.81,2-1.9,2.04c-.05,0-.09,0-.14,0H2.78c-1.09,0-1.97-.87-1.97-1.96,0,0,0,0,0,0Z" style="fill:#f5f5f5;"/></svg>
            </span>
        </div>
    </div>
</template>
<script>

    //esempio
    // data-cursor='{"label": "Indirizzo", "position":"svg"}'
    // data-cursor='{"color":"#D60812", "hover": "false"}'

    import gsap from 'gsap';

    export default {
        name: 'CustomMouse',
        props:{
            color:{
                type: String,
                default:'black'
            }
        },
        data: function() {
            return {
                label:null,
                canMoveCircle: true,
                elToFocusOn:null
            }
        },
        methods: {
            moveCursor(e){
                // gsap.to(this.$refs.cursor, 0.05, {
                //     css: {
                //         x: e.clientX,
                //         y: e.clientY
                //     }
                // });
                //X LOGICA FIXED A POSITION
                if (this.canMoveCircle) {
                    gsap.to(this.$refs.cursorCircle, 0.5, {
                        css: {
                            x: e.clientX,
                            y: e.clientY -15
                        }
                    });
                }
            },
            //EFFECTS
            initEffects(){
                let that = this;
                // document.body.style.cursor = 'none';
                document.body.style.overflowX = 'hidden';
                let objs = document.querySelectorAll('*[data-cursor]');

                let tl = gsap.timeline({paused:true});
                tl.addLabel('first')
                .to(this.$refs.cursorCircle, .25, {scale:7}, 'first')
                // .to(this.$refs.cursorCircleOut, .5, {strokeWidth:10}, 'first');

                objs.forEach(function(obj, i){

                    //MOUSEENTER EVENTS
                    obj.addEventListener('mouseenter',function(){
                        if (obj.dataset && obj.dataset.cursor) {

                            //SETTINGS
                            let customSetting = JSON.parse(obj.dataset.cursor);

                            if (!customSetting.hover) {
                                tl.play();
                            }
                            //LABEL
                            if (customSetting.label) {
                                that.$refs.text.innerHTML = customSetting.label;
                                //gsap.to(this.$refs.cursorCircle, {scale:5,duration:.25})
                                gsap.to(that.$refs.text, .25, {autoAlpha:1});
                            }

                            //ICON
                            if (customSetting.icon && customSetting.icon == 'ig') {
                                gsap.to(that.$refs.igicon, .25, {autoAlpha:1});
                            }
                            if (customSetting.icon && customSetting.icon == 'fb') {
                                gsap.to(that.$refs.fbicon, .25, {autoAlpha:1});
                            }
                            if (customSetting.icon && customSetting.icon == 'in') {
                                gsap.to(that.$refs.inicon, .25, {autoAlpha:1});
                            }
                            if (customSetting.icon && customSetting.icon == 'down') {
                                gsap.to(that.$refs.downicon, .25, {autoAlpha:1});
                            }
                            if (customSetting.icon && customSetting.icon == 'scroll') {
                                gsap.to(that.$refs.scrollicon, .25, {autoAlpha:1});
                            }
                            if (customSetting.icon && customSetting.icon == 'prev') {
                                gsap.to(that.$refs.previcon, .25, {autoAlpha:1});
                            }
                            if (customSetting.icon && customSetting.icon == 'next') {
                                gsap.to(that.$refs.nexticon, .25, {autoAlpha:1});
                            }
                            //CHANGE COLOR
                            if (customSetting.color) {
                                // gsap.to(that.$refs.cursorCircleOut, .25, {attr: {stroke:customSetting.color}});
                                // gsap.to(that.$refs.cursorCircleIn, .25, {attr: {fill:customSetting.color}});
                                gsap.to(that.$refs.cursorCircleOut, .001, {attr: {fill:customSetting.color}});
                            }

                            if(customSetting.visibility && customSetting.visibility == 'hidden'){
                                gsap.to(that.$refs.cursorCircle, .25, {autoAlpha:0});
                            }

                            //FIXED TO POSITION
                            if (customSetting.position) {
                                // that.canMoveCircle = false;
                                that.elToFocusOn = obj.querySelector(customSetting.position);
                                if(that.elToFocusOn){
                                    let { left, top, width, height } = that.elToFocusOn.getBoundingClientRect();
                                    gsap.to(that.$refs.cursorCircle, 0.3, {
                                        css: {
                                            x: (left + width / 2),
                                            y: (top + height / 2)
                                        }
                                    });
                                }
                            }
                        }
                    });

                    //MOUSEOUT EVENTS
                    obj.addEventListener('mouseleave',function(){

                        //SETTINGS
                        let customSetting = JSON.parse(obj.dataset.cursor);

                        if (!customSetting.hover) {
                            tl.reverse();
                        }
                        gsap.to(that.$refs.text, .25, {autoAlpha:0});
                        gsap.to(that.$refs.igicon, .25, {autoAlpha:0});
                        gsap.to(that.$refs.fbicon, .25, {autoAlpha:0});
                        gsap.to(that.$refs.inicon, .25, {autoAlpha:0});
                        gsap.to(that.$refs.downicon, .25, {autoAlpha:0});
                        gsap.to(that.$refs.scrollicon, .25, {autoAlpha:0});
                        gsap.to(that.$refs.previcon, .25, {autoAlpha:0});
                        gsap.to(that.$refs.nexticon, .25, {autoAlpha:0});
                        gsap.to(that.$refs.cursorCircle, .25, {autoAlpha:1});
                        gsap.to(that.$refs.cursorCircleOut, .001, {attr: {fill:that.$props.color}});
                        // gsap.to(that.$refs.cursorCircleIn, .25, {attr: {fill:that.$props.color}});
                        that.canMoveCircle = true;
                        that.elToFocusOn = null;
                    });

                });

                //ALLINEO FAKE MOUSE
                document.addEventListener('mousemove',(e) => {
                    this.moveCursor(e);
                });

                //MANTENGO POSITION SE "POSITION" IN HOVER
                document.addEventListener('scroll', () => {
                    if (!that.canMoveCircle && that.elToFocusOn) {
                        let { left, top, width, height } = that.elToFocusOn.getBoundingClientRect();
                        gsap.to(that.$refs.cursorCircle, 0.3, {
                            css: {
                                x: (left + width / 2),
                                y: (top + height / 2)
                            }
                        });
                    }
                });

            }
        },
        mounted: function() {
            this.initEffects();
        }
    }
</script>

<style lang="scss" scoped>
    @import "./customMouse.scss";
</style>
